import { onAuthStateChanged } from 'firebase/auth'
import SideNavBarCliente from '../../../../components/SideNavBarCliente'
import { auth } from '../../../../config/firebase'
import './User-Style.css'
import { useEffect, useState } from 'react'

function User () {

   onAuthStateChanged(auth, user => {
     if (!user) {
       window.location.href = '/usuarios/login'
     } else {
           setImageUser(user.photoURL)
       setUserName(user.displayName)
       setEmail(user.email)
}
  })

  const [ImageUser, setImageUser] = useState('')
  const [UserName, setUserName] = useState('')
  const [Email, setEmail] = useState('')

  const [TogleEdit, setTogleEdit] = useState(false)

  return (
    <>
      <main className='NotOverFlow main'>
        <SideNavBarCliente />
        <section className='Sec'>
          <div className='Textoprincipal'>Area do usuario</div>
          <div className='FristInfos'>
            <div>
              <img
                src={
                  ImageUser
                    ? ImageUser
                    : 'https://firebasestorage.googleapis.com/v0/b/kawhe-alves-dos-santos-db4be.appspot.com/o/FotoPadr%C3%A3odeperfil.jpg?alt=media&token=48f51603-394f-47e1-a204-323b955ffe78'
                }
              />
              <button>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  height='16'
                  width='16'
                  viewBox='0 0 512 512'
                >
                  <path d='M410.3 231l11.3-11.3-33.9-33.9-62.1-62.1L291.7 89.8l-11.3 11.3-22.6 22.6L58.6 322.9c-10.4 10.4-18 23.3-22.2 37.4L1 480.7c-2.5 8.4-.2 17.5 6.1 23.7s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L387.7 253.7 410.3 231zM160 399.4l-9.1 22.7c-4 3.1-8.5 5.4-13.3 6.9L59.4 452l23-78.1c1.4-4.9 3.8-9.4 6.9-13.3l22.7-9.1v32c0 8.8 7.2 16 16 16h32zM362.7 18.7L348.3 33.2 325.7 55.8 314.3 67.1l33.9 33.9 62.1 62.1 33.9 33.9 11.3-11.3 22.6-22.6 14.5-14.5c25-25 25-65.5 0-90.5L453.3 18.7c-25-25-65.5-25-90.5 0zm-47.4 168l-144 144c-6.2 6.2-16.4 6.2-22.6 0s-6.2-16.4 0-22.6l144-144c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6z' />
                </svg>
              </button>
            </div>

            <div>
              <div>
                <p>{UserName}</p>
              </div>
            </div>
          </div>
          <div className='ContacInfos'>
            <nav>
              <p>Informações de contato</p>
              <button>
                {!TogleEdit ? (
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    height='16'
                    width='16'
                    viewBox='0 0 512 512'
                  >
                    <path d='M410.3 231l11.3-11.3-33.9-33.9-62.1-62.1L291.7 89.8l-11.3 11.3-22.6 22.6L58.6 322.9c-10.4 10.4-18 23.3-22.2 37.4L1 480.7c-2.5 8.4-.2 17.5 6.1 23.7s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L387.7 253.7 410.3 231zM160 399.4l-9.1 22.7c-4 3.1-8.5 5.4-13.3 6.9L59.4 452l23-78.1c1.4-4.9 3.8-9.4 6.9-13.3l22.7-9.1v32c0 8.8 7.2 16 16 16h32zM362.7 18.7L348.3 33.2 325.7 55.8 314.3 67.1l33.9 33.9 62.1 62.1 33.9 33.9 11.3-11.3 22.6-22.6 14.5-14.5c25-25 25-65.5 0-90.5L453.3 18.7c-25-25-65.5-25-90.5 0zm-47.4 168l-144 144c-6.2 6.2-16.4 6.2-22.6 0s-6.2-16.4 0-22.6l144-144c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6z' />
                  </svg>
                ) : (
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    height='16'
                    width='12'
                    viewBox='0 0 384 512'
                  >
                    <path d='M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z' />
                  </svg>
                )}
              </button>
            </nav>
            <main>
              <div>
                Email:{' '}
                {TogleEdit ? (
                  <input
                    type='text'
                    value={Email}
                    onChange={e => setEmail(e.target.value)}
                  ></input>
                ) : (
                  <p>{Email}</p>
                )}
              </div>
              <div>
                Name:{' '}
                {TogleEdit ? (
                  <input
                    type='text'
                    value={UserName}
                    onChange={e => setUserName(e.target.value)}
                  ></input>
                ) : (
                  <p>{UserName}</p>
                )}
              </div>
            </main>
          </div>
        </section>
      </main>
    </>
  )
}
export default User
