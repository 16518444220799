import { onAuthStateChanged, signOut } from "firebase/auth";
import { auth } from "../../config/firebase";
import "./SideNavBarCliente-style.css"
import { useState } from "react";
import { toast } from "react-toastify";

function SideNavBarCliente() {
    const [ImageUser, setImageUser] = useState("")
    onAuthStateChanged(auth, (user) => {
        setImageUser(user.photoURL)
    });
    function LogOut() {
        signOut(auth).then(() => {
        }).catch((error) => {
              toast.error("tente sair mais tarde")
          });
    }
    return (
        <>
            <nav className="SideNavBar">
                <ul>
                    <li>
                        <img src={ImageUser ? ImageUser : "https://firebasestorage.googleapis.com/v0/b/kawhe-alves-dos-santos-db4be.appspot.com/o/FotoPadr%C3%A3odeperfil.jpg?alt=media&token=48f51603-394f-47e1-a204-323b955ffe78"}></img>
                    </li>
                    <div className="LineSideNavBar"></div>
                    <li>
                        <a href="/usuario/Perfil">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 448 512">
                                <path d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z" />
                            </svg>
                        </a>
                    </li>
                    <li>
                        <a href="/usuario/Faturas">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM64 80c0-8.8 7.2-16 16-16h64c8.8 0 16 7.2 16 16s-7.2 16-16 16H80c-8.8 0-16-7.2-16-16zm0 64c0-8.8 7.2-16 16-16h64c8.8 0 16 7.2 16 16s-7.2 16-16 16H80c-8.8 0-16-7.2-16-16zm128 72c8.8 0 16 7.2 16 16v17.3c8.5 1.2 16.7 3.1 24.1 5.1c8.5 2.3 13.6 11 11.3 19.6s-11 13.6-19.6 11.3c-11.1-3-22-5.2-32.1-5.3c-8.4-.1-17.4 1.8-23.6 5.5c-5.7 3.4-8.1 7.3-8.1 12.8c0 3.7 1.3 6.5 7.3 10.1c6.9 4.1 16.6 7.1 29.2 10.9l.5 .1 0 0 0 0c11.3 3.4 25.3 7.6 36.3 14.6c12.1 7.6 22.4 19.7 22.7 38.2c.3 19.3-9.6 33.3-22.9 41.6c-7.7 4.8-16.4 7.6-25.1 9.1V440c0 8.8-7.2 16-16 16s-16-7.2-16-16V422.2c-11.2-2.1-21.7-5.7-30.9-8.9l0 0 0 0c-2.1-.7-4.2-1.4-6.2-2.1c-8.4-2.8-12.9-11.9-10.1-20.2s11.9-12.9 20.2-10.1c2.5 .8 4.8 1.6 7.1 2.4l0 0 0 0 0 0c13.6 4.6 24.6 8.4 36.3 8.7c9.1 .3 17.9-1.7 23.7-5.3c5.1-3.2 7.9-7.3 7.8-14c-.1-4.6-1.8-7.8-7.7-11.6c-6.8-4.3-16.5-7.4-29-11.2l-1.6-.5 0 0c-11-3.3-24.3-7.3-34.8-13.7c-12-7.2-22.6-18.9-22.7-37.3c-.1-19.4 10.8-32.8 23.8-40.5c7.5-4.4 15.8-7.2 24.1-8.7V232c0-8.8 7.2-16 16-16z" /></svg>
                        </a>
                    </li>

                    <li>
                        <button onClick={LogOut}>SAIR</button>
                    </li>
                </ul>
            </nav>
        </>
    )
}
export default SideNavBarCliente;