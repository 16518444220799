// CookieConsent.js
import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';

const CookieConsent = () => {
  const [accepted, setAccepted] = useState(Cookies.get('cookiesAccepted'));

  useEffect(() => {
    if (!accepted) {
      const timeoutId = setTimeout(() => {
        setAccepted(false);
      }, 1000);

      return () => clearTimeout(timeoutId);
    }
  }, [accepted]);

  const handleAccept = () => {
    Cookies.set('cookiesAccepted', 'true', { expires: 15 });
    setAccepted(true);
  };

  return !accepted && (
    <div className="cookie-consent">
      <p>Este site utiliza <a href="https://developer.mozilla.org/pt-BR/docs/Web/HTTP/Cookies">cookies.</a> Ao continuar, você concorda com o uso de cookies.</p>
      <button onClick={handleAccept}>Aceitar</button>
    </div>
  );
};

export default CookieConsent;