import { useEffect, useState } from "react";
import NavBar from "../../../../components/NAV-BAR";
import "./Calculadora-Style.css"
import * as math from 'mathjs';

function Calculadora() {
    const [pad, setpad] = useState(true)
    const [cien, setcien] = useState(false)
    const [data1, setdata1] = useState(false)
    const [data2, setdata2] = useState(false)
    const [moeda, setmoeda] = useState(false)
    const [volume, setvolume] = useState(false)
    const [area, setarea] = useState(false)
    const [compriento, setcomprimento] = useState(false)
    const [velocidade, set] = useState(false)
    const [pesso, setpeso] = useState(false)
    const [tempo, settempo] = useState(false)
    const [temp, settmep] = useState(false)
    const [pot, setpot] = useState(false)
    const [energia, setenergia] = useState(false)
    const [dados, setdados] = useState(false)
    const [press, setpress] = useState(false)
    const [ang, setang] = useState(false)
    const [valor, setvalor] = useState(false)

    const [thisisbtn, setthisisbtn] = useState(true)

    const [displaySec, setdisplaySec] = useState("")
    const [expressao, setExpressao] = useState("0");
    const [History, setHistory] = useState([]);

    const [isonepress, setisonepress] = useState(false)

    useEffect(()=>{
        
    }, [History])

    const handleCalcular = () => {
        try {
            if (expressao === "0") return
            setdisplaySec(expressao);
            const resultado = math.evaluate(expressao);
            setExpressao(resultado);
            const arr = History;
            History.push(expressao + "=" + resultado);
            setHistory(arr);
        } catch (error) {
            setExpressao('Erro na expressão');
        }
    };
    function Clean() {
        setExpressao("0")
        setdisplaySec("")
        setisonepress(false)
    }
    window.addEventListener("keypres", (e) => {
        if (e.which === 27) {
            Clean()
        }
    })
    function Press(key) {
        const str = expressao + key
        setExpressao(str)
    }
    function PressP() {
        if (isonepress === false) {
            const str = expressao + "("
            setExpressao(str)
            setisonepress(true)
        } else if (isonepress === true) {
            const str = expressao + ")"
            setExpressao(str)
            setisonepress(false)
        }
    }

    return (
        <>
            <head>
                <meta property="og:title" content="Calculadora || kawhe" />
                <meta property="og:url" content="https://kawhealvesdossantos.com.br/utilitarios/calculadora" />
                <meta property="og:image" content="https://firebasestorage.googleapis.com/v0/b/kawhe-alves-dos-santos-db4be.appspot.com/o/1Prancheta%201.png?alt=media&token=54351338-cfe9-4ec2-95f0-79da42a59f1b" />
                <meta property="og:description" content="Pagina pricipal dos protifolio" />
                <meta name="description" content="Pagina pricipal dos protifolio" />
            </head>

            <title>KAWHE | CALCULADORA</title>
            <NavBar />
            <main className="Main-calc">
                <nav className="Nav-Calc"></nav>
                <div className="Card-Calcs">
                    {
                        pad && (
                            <>
                                <div className="Nav-calc">
                                    <div className="Indt">
                                        {
                                            thisisbtn ? (
                                                <button>
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z" /></svg>
                                                </button>
                                            ) : (
                                                <img src="" alt="ico" />
                                            )
                                        }
                                        <h2>Padrão</h2>
                                    </div>
                                    <div>
                                        <button>
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 512 512"
                                            >
                                                <path
                                                    d="M75 75L41 41C25.9 25.9 0 36.6 0 57.9V168c0 13.3 10.7 24 24 24H134.1c21.4 0 32.1-25.9 17-41l-30.8-30.8C155 85.5 203 64 256 64c106 0 192 86 192 192s-86 192-192 192c-40.8 0-78.6-12.7-109.7-34.4c-14.5-10.1-34.4-6.6-44.6 7.9s-6.6 34.4 7.9 44.6C151.2 495 201.7 512 256 512c141.4 0 256-114.6 256-256S397.4 0 256 0C185.3 0 121.3 28.7 75 75zm181 53c-13.3 0-24 10.7-24 24V256c0 6.4 2.5 12.5 7 17l72 72c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-65-65V152c0-13.3-10.7-24-24-24z"
                                                />
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                                <div className="Display-calc">
                                    <div className="D-Top">{displaySec}</div>
                                    <div className="D-Bottom"><input id="Ipn_Exp" className="D-Bottom-Ipn" autoFocus type="text" value={expressao} onChange={e => setExpressao(e.target.value)} /></div>
                                </div>
                                <div className="Keys-calc">
                                    <div className="L-calc">
                                        <div className="D-btn"><button onClick={() => Press("%")}>%</button></div>
                                        <div className="D-btn"><button><img src="https://firebasestorage.googleapis.com/v0/b/kawhe-alves-dos-santos-db4be.appspot.com/o/ico1.png?alt=media&token=d1b3107e-0059-4b8e-a019-a0dba76d7b1a" alt="ico" /></button></div>
                                        <div className="D-btn"><button onClick={() => Press("7")} >7</button></div>
                                        <div className="D-btn"><button onClick={() => Press("4")} >4</button></div>
                                        <div className="D-btn"><button onClick={() => Press("1")} >1</button></div>
                                        <div className="D-btn"><button><img src="https://firebasestorage.googleapis.com/v0/b/kawhe-alves-dos-santos-db4be.appspot.com/o/ico2.png?alt=media&token=bdc91e56-b6f2-4c08-9535-be0576c255cb" alt="ico" /></button></div>
                                    </div>
                                    <div className="L-calc">
                                        <div className="D-btn"><button onClick={Clean}>CE</button></div>
                                        <div className="D-btn"><button><img className="xls" src="https://firebasestorage.googleapis.com/v0/b/kawhe-alves-dos-santos-db4be.appspot.com/o/ico5.png?alt=media&token=207520db-5024-4e14-98b1-40ca7705be53" alt="ico" /></button></div>
                                        <div className="D-btn"><button onClick={() => Press("8")} >8</button></div>
                                        <div className="D-btn"><button onClick={() => Press("5")} >5</button></div>
                                        <div className="D-btn"><button onClick={() => Press("2")} >2</button></div>
                                        <div className="D-btn"><button onClick={() => Press("0")} >0</button></div>
                                    </div>
                                    <div className="L-calc">
                                        <div className="D-btn"><button onClick={PressP}>()</button></div>
                                        <div className="D-btn"><button><img className="xls" src="https://firebasestorage.googleapis.com/v0/b/kawhe-alves-dos-santos-db4be.appspot.com/o/ico6.png?alt=media&token=f5db76e0-9649-4b60-adf7-3e9169056e8b" alt="ico" /></button></div>
                                        <div className="D-btn"><button onClick={() => Press("9")} >9</button></div>
                                        <div className="D-btn"><button onClick={() => Press("6")} >6</button></div>
                                        <div className="D-btn"><button onClick={() => Press("3")} >3</button></div>
                                        <div className="D-btn"><button onClick={() => Press(".")} >,</button></div>
                                    </div>
                                    <div className="L-calc">
                                        <div className="D-btn"><button><img className="xls" src="https://firebasestorage.googleapis.com/v0/b/kawhe-alves-dos-santos-db4be.appspot.com/o/ico7.png?alt=media&token=b9eb9ade-90ce-4391-9123-3bc71ccb9931" alt="ico" /></button></div>
                                        <div className="D-btn"><button onClick={() => Press("/")} ><img className="xls" src="https://firebasestorage.googleapis.com/v0/b/kawhe-alves-dos-santos-db4be.appspot.com/o/ico4.png?alt=media&token=3c2ef9f2-379b-459f-98d0-fbc213ebc721" alt="ico" /></button></div>
                                        <div className="D-btn"><button onClick={() => Press("*")} ><img src="https://firebasestorage.googleapis.com/v0/b/kawhe-alves-dos-santos-db4be.appspot.com/o/ico3.png?alt=media&token=5190f46f-62ad-4955-b67b-7e71ac394054" alt="ico" /></button></div>
                                        <div className="D-btn"><button onClick={() => Press("-")} >-</button></div>
                                        <div className="D-btn"><button onClick={() => Press("+")} >+</button></div>
                                        <div className="D-btn"><button onClick={handleCalcular}>=</button></div>
                                    </div>
                                </div>
                            </>
                        )
                    }
                </div>
            </main>
        </>
    )
}
export default Calculadora;