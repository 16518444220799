import "./Horario-Style.css"
import NavBar from "../../components/NAV-BAR"
import { useEffect, useState } from "react";
import { db } from "../../config/firebase";
import { doc, getDoc } from "firebase/firestore";

function Horario() {

    const [Horarios, setHorarios_Velha] = useState(
        [
            {
                seg: ["LO", "LO", "LO", "LO", "LO"],
                ter: ["LO", "LO", "LO", "LO", "LO"],
                qua: ["LO", "LO", "LO", "LO", "LO"],
                qui: ["LO", "LO", "LO", "LO", "LO"],
                sex: ["LO", "LO", "LO", "LO", "LO"]
            }
        ]
    )

    useEffect(() => {
        async function gethorario() {
            const Ref1 = doc(db, 'Comfigs', 'Horario');
            try {
                const docSnap = await getDoc(Ref1);
                if (docSnap.exists()) {
                    const horarios = docSnap.data().Horario;
                    setHorarios_Velha(horarios)
                } else {
                    console.log("Nenhum documento encontrado!");
                }
            } catch (error) {
                console.error("Erro ao ler horário:", error);
            }
        }
        gethorario()
    }, []);

    // const Horarios = [
    //     {
    //         seg: ["GEO", "SOC", "PÓS M", "ELETIVA", "ELETIVA"],
    //         ter: ["PORT", "HIST", "QUIM", "TRILHA", "FIS"],
    //         qua: ["BIO", "TRILHA", "TRILHA", "ES ORI", "EF"],
    //         qui: ["PORT", "PORT", "MAT", "MAT", "TRILHA"],
    //         sex: ["ARTES", "FILO", "PV", "MAT", "TRILHA"]
    //     }
    // ]

    return (
        <>

            <head>
                <meta property="og:title" content="KAWHE | HORARIO | ESCOLAR" />
                <meta property="og:url" content="https://kawhealvesdossantos.com.br/horario" />
                <meta property="og:image" content="https://firebasestorage.googleapis.com/v0/b/kawhe-alves-dos-santos-db4be.appspot.com/o/1Prancheta%201.png?alt=media&token=54351338-cfe9-4ec2-95f0-79da42a59f1b" />
                <meta property="og:description" content="HORARIO" />
                <meta name="description" content="HORARIO" />
            </head>

            <title>KAWHE | HORARIO | ESCOLAR</title>
            <main>
                <div class="Main-Horario">
                    <h2>3F</h2>
                    <div className="Horarios">
                        <div className="D_A">
                            <span>
                                D/A
                            </span>
                            <div>
                                {Horarios[0].seg.map((i, index) => (
                                    <div className="Aula">
                                        <div>{index + 1}°</div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="card-dia">
                            <span>
                                Segunda
                            </span>
                            <div>
                                {Horarios[0].seg.map((i, index) => (
                                    <div className={"Aula" + " " + i.replace(".", "").replace(" ", "_").replace(".", "")}>
                                        <div>{i}</div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="card-dia">
                            <span>
                                Terça
                            </span>
                            <div>

                                {Horarios[0].ter.map((i, index) => (
                                    <div className={"Aula" + " " + i.replace(".", "").replace(" ", "_").replace(".", "")}>
                                        <div>{i}</div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="card-dia">
                            <span>
                                Quarta
                            </span>
                            <div>

                                {Horarios[0].qua.map((i, index) => (
                                    <div className={"Aula" + " " + i.replace(".", "").replace(" ", "_").replace(".", "")}>
                                        <div>{i}</div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="card-dia">
                            <span>
                                Quinta
                            </span>
                            <div>
                                {Horarios[0].qui.map((i, index) => (
                                    <div className={"Aula" + " " + i.replace(".", "").replace(" ", "_").replace(".", "")}>
                                        <div>{i}</div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="card-dia">
                            <span>
                                Sexta
                            </span>
                            <div>

                                {Horarios[0].sex.map((i, index) => (
                                    <div className={"Aula" + " " + i.replace(".", "").replace(" ", "_").replace(".", "")}>
                                        <div>{i}</div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="horas">
                    <div className="horas1">
                        <div>1°</div>
                        <div>2°</div>
                        <div>3°</div>
                        <div>R</div>
                        <div>4°</div>
                        <div>5°</div>
                        <div>T</div>
                    </div>
                    <div className="horas2">
                        <div>7:20</div>
                        <div>8:08</div>
                        <div>8:56</div>
                        <div>9:44-10:00</div>
                        <div>10:00</div>
                        <div>10:48</div>
                        <div>11:36</div>
                    </div>
                </div>
            </main>
        </>
    )
}
export default Horario