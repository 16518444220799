import './CreatUser-Style.css'

function CreatUser () {
  return (
    <>
      <main className='MainLogin'>
        <div className='CardLogin2'>
          <p>Solicite um orçamento para ter acesso a esta pagina</p>
          <p>whatsapp: <a href="https://wa.me/5569993172767">55+ (69) 9 93172767</a></p>
          <p>Pagina de contato: <a href="https:kawhealvesdossantos.com.br/contato">/contato</a></p>
          <p>Email: <a href="mailto:contato@kawhealvesdossantos.com.br">contato@kawhealvesdossantos.com.br</a></p>
          <a href="/">Voltar</a>
        </div>
      </main>
    </>
  )
}
export default CreatUser
