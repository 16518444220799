import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage"
import { GoogleAuthProvider } from "firebase/auth";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
    apiKey: "AIzaSyDY6JpGcG1VqCWi5LfPnyl4kOMVcdm7RmM",
    authDomain: "kawhe-alves-dos-santos-db4be.firebaseapp.com",
    databaseURL: "https://kawhe-alves-dos-santos-db4be-default-rtdb.firebaseio.com",
    projectId: "kawhe-alves-dos-santos-db4be",
    storageBucket: "kawhe-alves-dos-santos-db4be.appspot.com",
    messagingSenderId: "1074140345530",
    appId: "1:1074140345530:web:926dc6276af8fc9dfa7e83",
    measurementId: "G-9JNBLHFMRR"
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
export const GoogleProvider = new GoogleAuthProvider();

const database = getDatabase(app);

export default database;
