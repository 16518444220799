import {
  browserSessionPersistence,
  onAuthStateChanged,
  setPersistence,
  signInWithEmailAndPassword,
  signOut
} from 'firebase/auth'
import './LoginUsers-Style.css'
import { auth } from '../../../config/firebase'
import { useState } from 'react'
import { toast } from 'react-toastify'

function LoginUser() {
  const [Email, setEmail] = useState('')
  const [Pass, setPass] = useState('')
  const [ViwPass, setViwPass] = useState(false)

  function HandleViwPas() {
    setViwPass(!ViwPass)
  }

  function Login() {
    if (Email === '') {
      toast.warn('Preencha o campo de email')
    } else if (Pass === '') {
      toast.warn('Preencha o campo de senha')
    } else {
      setPersistence(auth, browserSessionPersistence).then(() => {
        signInWithEmailAndPassword(auth, Email, Pass)
          .then(userCredential => {
            const user = userCredential.user
            console.log(user)
            window.location.href = '/usuario'
          })
          .catch(error => {
            console.log(error.code)
            if (error.code === 'auth/invalid-credential') {
              toast.warn('Usuário ou senha inválidos')
            }
          })
      })
    }
  }

  return (
    <>
      <main className='MainLogin'>
        <div className='CardLogin'>
          <a href='/'>
            <img src='https://firebasestorage.googleapis.com/v0/b/kawhe-alves-dos-santos-db4be.appspot.com/o/1Prancheta%201.png?alt=media&token=54351338-cfe9-4ec2-95f0-79da42a59f1b'></img>
          </a>
          <h5>Email:</h5>
          <input
            type='email'
            onChange={e => setEmail(e.target.value)}
            value={Email}
          />
          <h5>Senha:</h5>
          <input
            type={!ViwPass ? 'password' : 'text'}
            onChange={e => setPass(e.target.value)}
            value={Pass}
          />
          <span onClick={HandleViwPas}>
            {' '}
            {!ViwPass ? 'Mostra senha' : 'Oucultar senha'}{' '}
          </span>
          <button onClick={Login}>Entrar</button>
          <p>--------------ou--------------</p>
          <a href='/usuarios/create'>Inscreverse</a>
        </div>
      </main>
    </>
  )
}
export default LoginUser
