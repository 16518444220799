import { onAuthStateChanged, signOut } from "firebase/auth";
import { auth } from "../../../config/firebase";
import "./Usuario-Style.css"
import SideNavBarCliente from "../../../components/SideNavBarCliente";

function Usuario() {
    onAuthStateChanged(auth, (user) => {
        if (!user) {
            window.location.href = "/usuarios/login"
        }
        console.log(user)
    });

    return (
        <>
        <SideNavBarCliente/> 
        </>
    )
}
export default Usuario;