import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "../pages/home";
import Projetos from "../pages/projetos";
import Erro404 from "../pages/erros/404";
import ViwProjeto from "../pages/projetos/Viw";
import Contato from "../pages/Contato";
import LoginUser from "../pages/Users/Login";
import CreatUser from "../pages/Users/CreateUser";
import Usuario from "../pages/Users/Usuario";
import User from "../pages/Users/Usuario/User";
import Loja from "../pages/Loja";
import Redirects from "../pages/Redirects";
import Horario from "../pages/Horario";
import Foguetes from "../pages/Foguetes";
import HomeCalculadora from "../pages/Calculadora/home";
import Calculadora from "../pages/Calculadora/calculadoras/Calculadora";



function AppRoutes() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Home/>}/>
                <Route path="/projetos" element={<Projetos/>}/>
                <Route path="/projetos/viw/:N" element={<ViwProjeto/>}/>
                <Route path="/usuarios/login" element={<LoginUser/>}/>
                <Route path="/usuarios/create" element={<CreatUser/>}/>
                <Route path="/usuario" element={<Usuario/>}/>
                <Route path="/usuario/perfil" element={<User/>}/>
                <Route path="/contato" element={<Contato/>}/>
                <Route path="/loja" element={<Loja/>}/>
                <Route path="/encurtador/:Nome/:Ano/:Pasta" element={<Redirects/>}/>
                <Route path="/encurtador/:Nome/:Ano/:Pasta/:time" element={<Redirects/>}/>
                <Route path="/Horario" element={<Horario/>}/>
                <Route path="/foguetes" element={<Foguetes/>}/>
                <Route path="/Utilitarios" element={<HomeCalculadora/>}/>
                <Route path="/Utilitarios/calculadora" element={<Calculadora/>}/>
                <Route path="/*" element={<Erro404/>}/>
            </Routes>
        </BrowserRouter>
    )
}
export default AppRoutes;