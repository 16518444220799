import { useEffect, useRef, useState } from "react";
import NavBar from "../../components/NAV-BAR";
import "./Contato-Style.css"
import emailjs from "@emailjs/browser";
import { toast } from "react-toastify";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../config/firebase";

function Contato() {

    const [EmailContatoAtivo, setEmailContatoAtivo] = useState(false)
    useEffect(() => {
        const loadProjectDetails = async () => {
            try {
                const projectDoc = doc(db, 'Comfigs', 'Sis_Email');
                const projectSnapshot = await getDoc(projectDoc);
                if (projectSnapshot.exists()) {
                    const projectData = projectSnapshot.data();
                    setEmailContatoAtivo(projectData.Email_Contato);
                }
            } catch (error) {
                console.error(error);
            }
        };
        loadProjectDetails();
    }, []);

    const [ShowMap, setShowMap] = useState(false)
    const [Name, setName] = useState("")
    const [Company, setCompany] = useState("")
    const [Tell, setTell] = useState("")
    const [Email, setEmail] = useState("")
    const [Msg, setMsg] = useState("")

    const toastId = useRef(null);

    const [QTNE, setQTNE] = useState(0)

    function EnviarEmail() {
        console.log(EmailContatoAtivo)
        if (EmailContatoAtivo === false) {
            toast.error("Este sistema esta temporarimente desativado");
        } else if (Name === "") {
            toast.error("Preencha o seu nome")
        } else if (Company === "") {
            toast.error("Preencha qual empresa voçe representa")
        } else if (Tell === "") {
            toast.error("Preencha seu numero de telefone")
        } else if (Email === "") {
            toast.error("Preencha o que que saber")
        } else {
            toastId.current = toast("Email Mensagem", {
                autoClose: false,
                closeButton: false
            });
            var templateParams = {
                SUBJECT: "Novo potencial cliente mando mensagem",
                Nome: Name,
                Empresa: Company,
                Telefone: Tell,
                Email: Email,
                Msg: Msg,
                TO: "kawhealvesdossantos@gmail.com"
            };
            emailjs.send('service_btvbj1e', 'template_90s9dek', templateParams, "bTup3YaphfZrsBv1X")
                .then(function (response) {
                    if (response.status === 200) {
                        toast.update(toastId.current, {
                            render: "Enviado com sucesso",
                            type: toast.TYPE.SUCCESS,
                            autoClose: 5000,
                            closeButton: null,
                        });
                    } else {
                        if (QTNE !== 3) {
                            toast.update(toastId.current, {
                                render: "Erro ao enviar",
                                type: toast.TYPE.ERROR,
                                autoClose: 5000,
                                closeButton: null,
                            });
                            setQTNE(QTNE + 1)
                        } else {
                            toast.update(toastId.current, {
                                render: "atualize a pagina e tente novamente",
                                type: toast.TYPE.ERROR,
                                autoClose: 5000,
                                closeButton: null,
                            });
                        }
                    }
                })
        }
    }


    return (
        <>
            <head>
                <meta property="og:title" content="Portifolio || kawhe" />
                <meta property="og:url" content="https://kawhealvesdossantos.com.br" />
                <meta property="og:image" content="https://firebasestorage.googleapis.com/v0/b/kawhe-alves-dos-santos-db4be.appspot.com/o/1Prancheta%201.png?alt=media&token=54351338-cfe9-4ec2-95f0-79da42a59f1b" />
                <meta property="og:description" content="Pagina de contato" />
                <meta name="description" content="Pagina de contato" />
            </head>

            <title>KAWHE | CONTATO</title>
            <NavBar />
            <main>
                <section className="MsgSBV">
                    <img src="https://firebasestorage.googleapis.com/v0/b/kawhe-alves-dos-santos-db4be.appspot.com/o/Projetos%2Fimagens%2FESCRITORIO%20(NOVAMINA)%2Fcapa%2FFACHADA.png?alt=media&token=5e318954-7102-4a55-94a5-6fd535dae98c" alt="Foto de um projeto" />
                    <span>Seja bem vindo</span>
                </section>
                <section className="IISecContato">
                    <div className="TextosContato">
                        <div className="LineContato"></div>
                        <p>ARIQUEMES - RO</p>
                        <div className="LineContato"></div>
                        <p>Telefone:</p>
                        <p>(+55) 69 9 93172767</p>
                        <div className="LineContato"></div>
                        <p>Email:</p>
                        <p>kawhealvesdossantos@gmail.com</p>
                    </div>
                    <div>
                        <section id="FormContact">
                            <form>
                                <label htmlFor="name">Nome: <span>*</span></label>
                                {EmailContatoAtivo ?
                                    (
                                        <input type="name" name="name" id="name" onChange={(e) => setName(e.target.value)} value={Name} />
                                    ) :
                                    (
                                        <input disabled type="name" name="name" id="name" onChange={(e) => setName(e.target.value)} value={Name} />
                                    )
                                }

                                <label htmlFor="company">Empresa: <span>*</span></label>
                                {
                                    EmailContatoAtivo ? (
                                        <input type="company" name="company" id="company" onChange={(e) => setCompany(e.target.value)} value={Company} />
                                    ) :
                                        (
                                            <input disabled type="company" name="company" id="company" onChange={(e) => setCompany(e.target.value)} value={Company} />
                                        )
                                }

                                <label htmlFor="tell">Telefone: <span>*</span></label>
                                {
                                    EmailContatoAtivo ? (
                                        <input type="tell" name="tell" id="tell" onChange={(e) => setTell(e.target.value)} value={Tell} />
                                    ) :
                                        (
                                            <input disabled type="tell" name="tell" id="tell" onChange={(e) => setTell(e.target.value)} value={Tell} />
                                        )
                                }

                                <label htmlFor="email">Email: <span>*</span></label>
                                {
                                    EmailContatoAtivo ? (
                                        <input type="email" name="email" id="email" onChange={(e) => setEmail(e.target.value)} value={Email} />
                                    ) :
                                        (
                                            <input disabled type="email" name="email" id="email" onChange={(e) => setEmail(e.target.value)} value={Email} />
                                        )
                                }

                                <label htmlFor="mensage">Mensagem <span>*</span></label>
                                {
                                    EmailContatoAtivo ? (
                                        <textarea name="mensage" id="mesage" cols="30" rows="10" onChange={(e) => setMsg(e.target.value)} value={Msg} ></textarea>
                                    ) :
                                        (
                                            <textarea disabled name="mensage" id="mesage" cols="30" rows="10" onChange={(e) => setMsg(e.target.value)} value={Msg} ></textarea>
                                        )
                                }
                            </form>
                            <button onClick={EnviarEmail}>Enviar</button>
                        </section>
                    </div>
                </section>

                {
                    ShowMap && (
                        <section className="map">
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1965.2554404016112!2d-63.015993534242384!3d-9.89133933110462!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x93cc90aab1ad0305%3A0xa9813611c7a31e1c!2sR.%20Londres%2C%205550%20-%20Bela%20Vista%2C%20Ariquemes%20-%20RO%2C%2076875-512!5e0!3m2!1spt-BR!2sbr!4v1694742224946!5m2!1spt-BR!2sbr"
                                allowfullscreen="true"
                                loading="lazy"
                                referrerpolicy="no-referrer-when-downgrade">
                            </iframe>
                        </section>
                    )
                }
            </main>
        </>
    )
}
export default Contato;