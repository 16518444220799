import { useEffect, useState } from 'react';
import NavBar from '../../components/NAV-BAR';
import './index-projetos-style.css'
import { collection, onSnapshot, query } from 'firebase/firestore';
import { db } from '../../config/firebase';

function Projetos() {
    const [Projetos, setProjetos] = useState([])
    useEffect(() => {
        const q = query(collection(db, 'Projetos'));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            let todosArr = [];
            querySnapshot.forEach((doc) => {
                todosArr.push({ ...doc.data() });
            });
            setProjetos(todosArr);
        });
        return () => unsubscribe();
    }, []);

    return (
        <>
            <head>
                <meta property="og:title" content="Portifolio || kawhe" />
                <meta property="og:url" content="https://kawhealvesdossantos.com.br" />
                <meta property="og:image" content="https://firebasestorage.googleapis.com/v0/b/kawhe-alves-dos-santos-db4be.appspot.com/o/1Prancheta%201.png?alt=media&token=54351338-cfe9-4ec2-95f0-79da42a59f1b" />
                <meta property="og:description" content="Pagina pricipal dos protifolio" />
                <meta name="description" content="Pagina pricipal dos protifolio" />
            </head>

            <title>KAWHE | PROJETOS</title>
            <NavBar />
            <main>
                <div class="portifolio">
                    {
                        Projetos && Projetos.map((i) => (
                            <div class="min-prj p1">
                                <a href={`/projetos/viw/${i.Name}`}>
                                    <img className="ImageProjeto image-prj" src={i.Url_Capa} alt="PROJETO 01"></img>
                                </a>
                                <div class="text-s">
                                    <p class="text">{i.Name}</p>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </main>
        </>
    )
}
export default Projetos;