import { useEffect, useRef, useState } from 'react';
import "./Foguete-Style.css"

function Foguetes() {

    const [ang, setAng] = useState(45)
    const [val, setVal] = useState(32)
    const [G, setG] = useState(9.8)

    const [COS, setCOS] = useState(0)
    const [SEN, setSEN] = useState(0)

    useEffect(() => {
        var anguloEmRadianos = ang * (Math.PI / 180);
        var cosseno = Math.cos(anguloEmRadianos);
        setCOS(cosseno.toFixed(2))

        var anguloEmRadianos = ang * (Math.PI / 180);
        var seno = Math.sin(anguloEmRadianos);
        setSEN(seno.toFixed(2))
    }, [ang])

    function calcularTrajetoria(angulo) {
        var pontos = [];
        var g = G; // Aceleração devido à gravidade (m/s^2)
        var tempoTotal = (2 * val * Math.sin(angulo)) / g; // Tempo total de voo
        for (var t = 0; t <= tempoTotal; t += 0.1) { // Passo de 0.1 segundo
            var x = val * Math.cos(angulo) * t;
            var y = val * Math.sin(angulo) * t - 0.5 * g * t * t;
            pontos.push({ x: x, y: y });
        }
        return pontos;
    }

    var angulo = ang * Math.PI / 180;
    var trajetoria = calcularTrajetoria(angulo);
    console.log(trajetoria);

    // Função para encontrar o ponto mais alto da trajetória
    function encontrarPontoMaisAltoSeparado(velocidade, angulo) {
        var g = 9.81; // Aceleração devido à gravidade (m/s^2)
        var alturaMaxima = (velocidade * Math.sin(angulo)) ** 2 / (2 * g); // Fórmula para a altura máxima
        var pontoMaisAlto = alturaMaxima * 2.5;
        return pontoMaisAlto;
    }
    var velocidadeInicial = val; // Velocidade inicial em m/s
    var angulo1 = ang * Math.PI / 180;
    var pontoMaisAltoSeparado = encontrarPontoMaisAltoSeparado(velocidadeInicial, angulo1);

    useEffect(() => {
        const canvas = document.getElementById("Canvas")
        const ctx = canvas.getContext("2d")

        // Define o tamanho da margem
        var margin = 50;

        ctx.clearRect(0, 0, canvas.width, canvas.height);

        // const Tempo = ((val * SEN) / G).toFixed(2)
        const Vy = val * SEN
        const H = Vy
        const H1 = 350 - (H * 2.5)
        const S = ((val * COS) * ((val * SEN) / G).toFixed(2) * 2).toFixed(2)
        const D = 50 + (S * 2.5)
        const D1 = D / 2 + 25

        var startPoint = { x: 50, y: 350 };
        var endPoint = { x: D, y: 350 };

        // Define os três pontos para o arco
        var controlPoint2 = { x: D1, y: H1 };
        var controlPoint = { x: startPoint.x + (endPoint.x - startPoint.x) / 2, y: startPoint.y - (startPoint.y - H1) * 2 };

        for (var i = 0; i < trajetoria.length; i++) {
            var ponto = trajetoria[i];
            // Desenhe o ponto no canvas (pode ser um círculo, um quadrado, etc.)
            ctx.beginPath();
            if (ponto.x < 0 && ponto.y < 0) {
                ctx.arc(ponto.x + 50, 350 - ponto.y, 2, 0, Math.PI * 2)
            } else (
                ctx.arc(ponto.x * 2.5 + 50, 350 - (ponto.y * 2.5), 2, 0, Math.PI * 2)
            )
            ctx.fillStyle = 'blueviolet';
            ctx.fill();
        }

        // Configurações de estilo do texto
        ctx.font = "11px Arial"; // Fonte e tamanho
        ctx.fillStyle = "red"; // Cor do texto
        ctx.textAlign = "left"; // Alinhamento horizontal
        ctx.textBaseline = "middle"; // Alinhamento vertical

        // Escreve o texto no canvas
        ctx.fillText(S + " m", endPoint.x, 365); // fillText para preencher o texto
        ctx.fillText((H.toFixed(2)) + " m", controlPoint2.x, 350 - pontoMaisAltoSeparado - 10); // fillText para preencher o texto
        ctx.fillText(ang + " º", startPoint.x + 30, startPoint.y - 10); // fillText para preencher o texto
        ctx.fillText("Vy", 30, 50); // fillText para preencher o texto
        ctx.fillText(val * SEN + " m/s", 0, 65); // fillText para preencher o texto
        ctx.fillText("Vx", 530, 360); // fillText para preencher o texto
        ctx.fillText(val * COS+ " m/s", 500, 370); // fillText para preencher o texto

        var arrowSize = 4;
        var arrowColor1 = "blue";
        var arrowColor2 = "red";

        // Eixo X
        ctx.beginPath();
        ctx.lineWidth = 0.7;
        ctx.moveTo(margin, 350);
        ctx.lineTo(canvas.width - margin, 350);
        ctx.moveTo(canvas.width - margin - arrowSize, 350 - arrowSize);
        ctx.lineTo(canvas.width - margin, 350);
        ctx.lineTo(canvas.width - margin - arrowSize, 350 + arrowSize);
        ctx.strokeStyle = arrowColor1;
        ctx.stroke();

        // Eixo Y
        ctx.beginPath();
        ctx.lineWidth = 0.7;
        ctx.moveTo(50, margin);
        ctx.lineTo(50, canvas.height - margin);
        ctx.moveTo(50 - arrowSize, margin + arrowSize);
        ctx.lineTo(50, margin);
        ctx.lineTo(50 + arrowSize, margin + arrowSize);
        ctx.strokeStyle = arrowColor2;
        ctx.stroke();

        var gridSize = 25;
        var gridColor = "#ccc";

        ctx.beginPath();
        ctx.strokeStyle = gridColor;

        // Desenha linhas horizontais
        for (var y = margin + gridSize; y < canvas.height - margin; y += gridSize) {
            ctx.lineWidth = 0.5;
            ctx.moveTo(margin, y);
            ctx.lineTo(canvas.width - margin, y);
        }

        // Desenha linhas verticais
        for (var x = margin + gridSize; x < canvas.width - margin; x += gridSize) {
            ctx.lineWidth = 0.5;
            ctx.moveTo(x, margin);
            ctx.lineTo(x, canvas.height - margin);
        }

        ctx.stroke();


        // // Desenha o arco
        // ctx.beginPath();
        // ctx.lineWidth = 3;
        // ctx.moveTo(startPoint.x, startPoint.y);
        // ctx.quadraticCurveTo(controlPoint.x, controlPoint.y, endPoint.x, endPoint.y); // Usa quadraticCurveTo para desenhar um arco
        // ctx.strokeStyle = "blueviolet";
        // ctx.stroke();

        // drawPoint(controlPoint2);
        // drawPoint(controlPoint);
        const pointmax = { x: controlPoint.x, y: 350 - pontoMaisAltoSeparado }
        drawPoint(pointmax);
        drawPoint(endPoint);


        function drawArrow(x, y, angle) {
            // Se o comprimento da seta não for fornecido, use um valor padrão de 10
            var arrowLength = ang * 3.5;

            // Comprimento da haste da seta
            var stemLength = ang * 3.5; // Ajuste o multiplicador conforme desejado

            // Calcula as coordenadas dos pontos finais da linha da seta
            var stemEndX = x + stemLength * Math.cos(angle);
            var stemEndY = y - stemLength * Math.sin(angle); // Use subtração para inverter a direção Y
            var arrowEndX = x + arrowLength * Math.cos(angle);
            var arrowEndY = y - arrowLength * Math.sin(angle); // Use subtração para inverter a direção Y

            // Desenha a linha da haste da seta
            ctx.beginPath();
            ctx.moveTo(x, y);
            ctx.lineTo(stemEndX, stemEndY);
            ctx.stroke();

            // Comprimento da ponta da seta (pode ser ajustado conforme desejado)
            var tipLength = 10;

            // Calcula as coordenadas dos pontos da seta
            var arrowPoint1X = arrowEndX - tipLength * Math.cos(angle + Math.PI / 6);
            var arrowPoint1Y = arrowEndY + tipLength * Math.sin(angle + Math.PI / 6); // Use adição para inverter a direção Y
            var arrowPoint2X = arrowEndX - tipLength * Math.cos(angle - Math.PI / 6);
            var arrowPoint2Y = arrowEndY + tipLength * Math.sin(angle - Math.PI / 6); // Use adição para inverter a direção Y

            // Desenha a ponta da seta
            ctx.beginPath();
            ctx.moveTo(arrowEndX, arrowEndY);
            ctx.lineTo(arrowPoint1X, arrowPoint1Y);
            ctx.moveTo(arrowEndX, arrowEndY);
            ctx.lineTo(arrowPoint2X, arrowPoint2Y);
            ctx.stroke();

            // Calcula as coordenadas do ponto médio da seta
            var midX = (x + arrowEndX) / 2;
            var midY = (y + arrowEndY) / 2;

            // Desenha o texto alinhado ao centro da seta
            ctx.save();
            ctx.translate(midX, midY);
            ctx.rotate(ang * Math.PI / -180);
            ctx.textAlign = 'center';
            ctx.fillText(val + " m/s", 0, -10);
            ctx.restore();
        }

        // Exemplo de uso da função drawArrow
        var arrowX = 50; // Posição X do ponto inicial da seta
        var arrowY = 350; // Posição Y do ponto inicial da seta
        var arrowAngle = ang * Math.PI / 180; // Ângulo da seta em radianos (45 graus)
        drawArrow(arrowX, arrowY, arrowAngle);


        function drawPoint(point) {
            ctx.beginPath();
            ctx.arc(point.x, point.y, 2.5, 0, Math.PI * 2);
            ctx.fillStyle = "red";
            ctx.fill();
        }

    }, [val, ang, G])



    return (
        <>
            <head>
                <meta property="og:title" content="KAWHE | FOGUETES | ESCOLAR" />
                <meta property="og:url" content="https://kawhealvesdossantos.com.br/foguetes" />
                <meta property="og:image" content="https://firebasestorage.googleapis.com/v0/b/kawhe-alves-dos-santos-db4be.appspot.com/o/1Prancheta%201.png?alt=media&token=54351338-cfe9-4ec2-95f0-79da42a59f1b" />
                <meta property="og:description" content="CALC. FOGUETES" />
                <meta name="description" content="CALC. FOGUETES" />
            </head>
            <title>KAWHE | FOGUETES | ESCOLAR</title>

            <main>
                <section className='left'>
                    <section className='Top'>
                        <section className='H'>
                            <div className='H-ang'><span>Angulo de lançamento Θ </span><input type="number" name="Ang" id="Ang" onChange={e => setAng(e.target.value)} value={ang} /></div>
                            <div className='H-vel'><span>Velociade de lançamento</span><input type="number" name="Vel" id="Vel" onChange={e => setVal(e.target.value)} value={val} /> <span>{val * 3.6} km/h</span></div>
                            <div className='H-g'><span>Gravidade </span><input type="number" name="G" id="G" onChange={e => setG(e.target.value)} value={G} /></div>
                            <div><span>Resultado = {((val * COS) * ((val * SEN) / G).toFixed(2) * 2).toFixed(2)}m</span></div>
                        </section>
                        <section>
                            <div><span>SEN <span>{ang}º</span></span><span>= {COS}</span></div>
                            <div><span>COS <span>{ang}º</span></span><span>= {SEN}</span></div>
                        </section>
                    </section>
                    <section>
                        <div className='leftBT'>
                            <div className='Vx'>
                                <span className='linha'><span>V<span className='dow'>x</span></span><span>=</span><span>V<span className='dow'>0</span></span><span>*</span><span>COS Θ</span></span>
                                <span className='linha'><span>V<span className='dow'>x</span></span><span>=</span><span>{val}</span><span>*</span><span>{COS}</span></span>
                                <span className='linha'><span>V<span className='dow'>x</span></span><span>=</span><span>{val * COS}m/s</span></span>
                            </div>
                            <div className='V0y'>
                                <span className='linha'><span>V<span className='dow'>y</span></span><span>=</span><span>V<span className='dow'>0</span></span><span>*</span><span>SEN Θ</span></span>
                                <span className='linha'><span>V<span className='dow'>y</span></span><span>=</span><span>{val}</span><span>*</span><span>{SEN}</span></span>
                                <span className='linha'><span>V<span className='dow'>y</span></span><span>=</span><span>{val * SEN}m/s</span></span>
                            </div>
                        </div>
                        <div className='leftBB'>
                            <div className='Vy'>
                                <span className='linha'><span>V<span className='dow'>y</span></span><span>=</span><span>V<span className='dow'>0y</span></span><span>-</span><span>g</span><span>*</span><span>T</span></span>
                                <span className='linha'><span>0<span className='dow'></span></span><span>=</span><span>{val * SEN}<span className='dow'></span></span><span>-</span><span>{G}</span><span>*</span><span>T</span></span>
                                <span className='linha'><span>{G}</span> * T = <span>{val * SEN}</span></span>
                                <span className='linha'>T = <span>{val * SEN}</span> / <span>{G}</span></span>
                                <span className='linha'>T = <span>{((val * SEN) / G).toFixed(2)}s</span></span>
                            </div>
                            <div className='S'>
                                <span className='linha'><span>S</span><span> = </span><span>V<span className='dow'>x</span></span><span> * </span><span>T </span><span>*</span><span> 2</span></span>
                                <span className='linha'><span>S</span><span> = </span><span>{val * COS}<span className='dow'></span></span><span> * </span><span>{((val * SEN) / G).toFixed(2)} </span><span>*</span><span> 2</span></span>
                                <span className='linha'><span>S</span><span> = </span><span>{((val * COS) * ((val * SEN) / G).toFixed(2) * 2).toFixed(2)}m</span></span>
                            </div>
                        </div>
                    </section>
                    <div className='card-grafico'>
                        <div className='grafico'>
                            <canvas id='Canvas' width={600} height={400}></canvas>
                        </div>
                    </div>
                </section>
            </main>
        </>
    )
}
export default Foguetes