import { useParams } from 'react-router-dom'
import './ViwProjeto-Style.css'
import { useEffect, useState } from 'react'
import { db } from '../../../config/firebase'
import { doc, getDoc } from 'firebase/firestore'
import NavBar from '../../../components/NAV-BAR'

function ViwProjeto() {
  const NomeDoProjeto = useParams()

  const [Projeto, setProjeto] = useState('')
  const [TypeContent, setTypeContent] = useState('')

  useEffect(() => {
    if (NomeDoProjeto.N !== '') {
      const loadProjectDetails = async () => {
        try {
          const projectDoc = doc(db, 'Projetos', NomeDoProjeto.N)
          const projectSnapshot = await getDoc(projectDoc)
          if (projectSnapshot.exists()) {
            const projectData = projectSnapshot.data()
            setProjeto([
              projectData.Date,
              projectData.Estructure,
              projectData.FinalProject,
              projectData.From,
              projectData.Id,
              projectData.Name,
              projectData.Pdf,
              projectData.PreProject,
              projectData.Render,
              projectData.Type,
              projectData.Url_Capa,
              projectData.Url_Photo,
              projectData.Url_Video
            ])
            const uniqueUrlPhotoTypes = Array.from(
              new Set(projectData.Url_Photo.map(photo => photo.Type))
            );

            // Atualiza o estado com os valores únicos de 'Type'
            setTypeContent(uniqueUrlPhotoTypes);

          } else {
            alert('nao foi possiel achar: ' + NomeDoProjeto.N)
          }
        } catch (error) {
          console.error(error)
        }
      }
      loadProjectDetails()
    }
  }, [NomeDoProjeto.N])

  const FotosProjetos = Projeto[11]

  const [RenderIsOpen, setRenderIsOpen] = useState(false)
  const [ProjetoARQIsOpen, setProjetoARQIsOpen] = useState(false)
  const [ProjetoMECIsOpen, setProjetoMECIsOpen] = useState(false)
  const [ProjetoESTIsOpen, setProjetoESTIsOpen] = useState(false)
  const [ProjetoHIDIsOpen, setProjetoHIDIsOpen] = useState(false)
  const [ProjetoELEIsOpen, setProjetoELEIsOpen] = useState(false)
  const [ProjetoPPCIPIsOpen, setProjetoPPCIPIsOpen] = useState(false)

  function HandleClickRender() {
    setRenderIsOpen(!RenderIsOpen)
  }

  function HandleClickProjetoARQ() {
    setProjetoARQIsOpen(!ProjetoARQIsOpen)
  }

  function HandleClickProjetoMEC() {
    setProjetoMECIsOpen(!ProjetoMECIsOpen)
  }

  function HandleClickProjetoEST() {
    setProjetoESTIsOpen(!ProjetoESTIsOpen)
  }

  function HandleClickProjetoHID() {
    setProjetoHIDIsOpen(!ProjetoHIDIsOpen)
  }

  function HandleClickProjetoELE() {
    setProjetoELEIsOpen(!ProjetoELEIsOpen)
  }

  function HandleClickProjetoPPCIP() {
    setProjetoPPCIPIsOpen(!ProjetoPPCIPIsOpen)
  }

  const FotosRender = FotosProjetos && FotosProjetos.filter(item => item.Type === 'RENDER');
  const FotosProjetoAQR = FotosProjetos && FotosProjetos.filter(item => item.Type === 'ARQ.');
  const FotosProjetoMEC = FotosProjetos && FotosProjetos.filter(item => item.Type === 'MEC.');
  const FotosProjetoEST = FotosProjetos && FotosProjetos.filter(item => item.Type === 'EST.');
  const FotosProjetoHID = FotosProjetos && FotosProjetos.filter(item => item.Type === 'HID.');
  const FotosProjetoELE = FotosProjetos && FotosProjetos.filter(item => item.Type === 'ELE.');
  const FotosProjetoPPCIP = FotosProjetos && FotosProjetos.filter(item => item.Type === 'PPCIP');

  return (
    <>
      <head>
        <meta property="og:title" content={`KAWHE | PROJETO | ${Projeto[5]}`} />
        <meta property="og:url" content={`https://kawhealvesdossantos.com.br/projetos/viw/${Projeto[5]}`} />
        <meta property="og:image" content={`${Projeto[10]}`} />
        <meta property="og:description" content={`Projeto ${Projeto[5]}`} />
        <meta name="description" content={`Projeto ${Projeto[5]}`} />
      </head>

      {Projeto !== '' && <title>KAWHE | PROJETO | {Projeto[5]}</title>}
      <NavBar />
      <main className='MainViwProjetos'>
        {Projeto !== '' && (
          <div key={Projeto[4]}>
            <div className='itenscarrosel'>
              <div className='STextosHome'>
                <div className='LineHomeTextos'></div>
                <h1>{Projeto[5]}</h1>
                <div className='LineHomeTextos'></div>
                <h4>INFORMAÇÃOES DO PROJETO</h4>
                <div className='LineHomeTextos'></div>
                <div className='ItensTextosHome'>
                  <h6>PROJETO: {Projeto[9]}</h6>
                  <h6>ESTRUTURA: {Projeto[1]}</h6>
                  <h6>PARA: {Projeto[3]}</h6>
                </div>
                <div className='LineHomeTextos'></div>
                <h4>INFORMAÇÃOES DE SOFTWARE</h4>
                <div className='LineHomeTextos'></div>
                <div className='ItensTextosHome'>
                  <h6>PROJETO FINAL: {Projeto[2]}</h6>
                  <h6>PRÉ PROJETO: {Projeto[7]}</h6>
                  <h6>RENDER: {Projeto[8]}</h6>
                  <h6>PDF: {Projeto[6]}</h6>
                </div>
                <div className='LineHomeTextos'></div>
                <h4>{Projeto[0]}</h4>
                <div className='LineHomeTextos'></div>
              </div>

              <div className='SImageHome'>
                <img src={Projeto[10]} alt='' />
              </div>
            </div>
          </div>
        )}

        <section className='Video-3D'>
          {Projeto[12] !== '' && (
            <iframe
              src={Projeto[12]}
              title='YouTube video player'
              frameborder='0'
              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
              allowfullscreen
            ></iframe>
          )}

          {/* {
          Projeto[5] === "ESTOQUE (NOVA-MINA)" && (
            <div class="sketchfab-embed-wrapper">
            <iframe
            title="ARQ-ESTOQUE-Vista3D-3D-Det-Geral"
            frameborder="0"
            allowfullscreen mozallowfullscreen="true"
            webkitallowfullscreen="true"
            allow="autoplay; fullscreen; xr-spatial-tracking"
            xr-spatial-tracking
            execution-while-out-of-viewport
            execution-while-not-rendered
            web-share
            src="https://sketchfab.com/models/134e53b913a34c4f92f7bd04176263f8/embed"></iframe>
            </div>
            )
          } */}
        </section>

        {
          TypeContent.includes("ARQ.") &&
          <section className='Drop'>
            <div className='HeaderDrop' onClick={HandleClickProjetoARQ} style={{ marginBottom: !ProjetoARQIsOpen ? "10px" : "0" }}>
              {!ProjetoARQIsOpen ?
                <svg xmlns="http://www.w3.org/2000/svg" height="16" width="14" viewBox="0 0 448 512"><path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" /></svg>
                :
                <svg xmlns="http://www.w3.org/2000/svg" height="16" width="12" viewBox="0 0 384 512"><path d="M169.4 470.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 370.8 224 64c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 306.7L54.6 265.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" /></svg>
              }
              {"PRANCHA ARQUITETONICA"}
            </div>
            {ProjetoARQIsOpen && (
              <div>
                {FotosProjetoAQR &&
                  FotosProjetoAQR.map((i) => (
                    <img src={i.url} alt={i.name} className={`FotosProjetos ${i.Name}`}></img>
                  ))}
              </div>
            )}
          </section>
        }

        {
          TypeContent.includes("EST.") &&
          <section className='Drop'>
            <div className='HeaderDrop' onClick={HandleClickProjetoEST} style={{ marginBottom: !ProjetoESTIsOpen ? "10px" : "0" }}>
              {!ProjetoESTIsOpen ?
                <svg xmlns="http://www.w3.org/2000/svg" height="16" width="14" viewBox="0 0 448 512"><path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" /></svg>
                :
                <svg xmlns="http://www.w3.org/2000/svg" height="16" width="12" viewBox="0 0 384 512"><path d="M169.4 470.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 370.8 224 64c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 306.7L54.6 265.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" /></svg>
              }
              {"PRANCHA ESTRUTURAL"}
            </div>
            {ProjetoESTIsOpen && (
              <div>
                {FotosProjetoEST &&
                  FotosProjetoEST.map((i) => (
                    <img src={i.url} alt={i.name} className={`FotosProjetos ${i.Name}`}></img>
                  ))}
              </div>
            )}
          </section>
        }

        {
          TypeContent.includes("HID.") &&
          <section className='Drop'>
            <div className='HeaderDrop' onClick={HandleClickProjetoARQ} style={{ marginBottom: !ProjetoHIDIsOpen ? "10px" : "0" }}>
              {!ProjetoHIDIsOpen ?
                <svg xmlns="http://www.w3.org/2000/svg" height="16" width="14" viewBox="0 0 448 512"><path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" /></svg>
                :
                <svg xmlns="http://www.w3.org/2000/svg" height="16" width="12" viewBox="0 0 384 512"><path d="M169.4 470.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 370.8 224 64c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 306.7L54.6 265.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" /></svg>
              }
              {"PRANCHA HIDRAULICA"}
            </div>
            {ProjetoHIDIsOpen && (
              <div>
                {FotosProjetoHID &&
                  FotosProjetoHID.map((i) => (
                    <img src={i.url} alt={i.name} className={`FotosProjetos ${i.Name}`}></img>
                  ))}
              </div>
            )}
          </section>
        }

        {
          TypeContent.includes("ELE.") &&
          <section className='Drop'>
            <div className='HeaderDrop' onClick={HandleClickProjetoARQ} style={{ marginBottom: !ProjetoELEIsOpen ? "10px" : "0" }}>
              {!ProjetoELEIsOpen ?
                <svg xmlns="http://www.w3.org/2000/svg" height="16" width="14" viewBox="0 0 448 512"><path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" /></svg>
                :
                <svg xmlns="http://www.w3.org/2000/svg" height="16" width="12" viewBox="0 0 384 512"><path d="M169.4 470.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 370.8 224 64c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 306.7L54.6 265.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" /></svg>
              }
              {"PRANCHA ELETRICA"}
            </div>
            {ProjetoELEIsOpen && (
              <div>
                {FotosProjetoELE &&
                  FotosProjetoELE.map((i) => (
                    <img src={i.url} alt={i.name} className={`FotosProjetos ${i.Name}`}></img>
                  ))}
              </div>
            )}
          </section>
        }

        {
          TypeContent.includes("PPCIP") &&
          <section className='Drop'>
            <div className='HeaderDrop' onClick={HandleClickProjetoARQ} style={{ marginBottom: !ProjetoPPCIPIsOpen ? "10px" : "0" }}>
              {!ProjetoPPCIPIsOpen ?
                <svg xmlns="http://www.w3.org/2000/svg" height="16" width="14" viewBox="0 0 448 512"><path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" /></svg>
                :
                <svg xmlns="http://www.w3.org/2000/svg" height="16" width="12" viewBox="0 0 384 512"><path d="M169.4 470.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 370.8 224 64c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 306.7L54.6 265.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" /></svg>
              }
              {"PRANCHA PPCIP"}
            </div>
            {ProjetoPPCIPIsOpen && (
              <div>
                {FotosProjetoPPCIP &&
                  FotosProjetoPPCIP.map((i) => (
                    <img src={i.url} alt={i.name} className={`FotosProjetos ${i.Name}`}></img>
                  ))}
              </div>
            )}
          </section>
        }

        {
          TypeContent.includes("MEC.") &&
          <section className='Drop'>
            <div className='HeaderDrop' onClick={HandleClickProjetoMEC} style={{ marginBottom: !ProjetoMECIsOpen ? "10px" : "0" }}>
              {!ProjetoMECIsOpen ?
                <svg xmlns="http://www.w3.org/2000/svg" height="16" width="14" viewBox="0 0 448 512"><path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" /></svg>
                :
                <svg xmlns="http://www.w3.org/2000/svg" height="16" width="12" viewBox="0 0 384 512"><path d="M169.4 470.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 370.8 224 64c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 306.7L54.6 265.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" /></svg>
              }
              {"PRANCHA MECÂNICA"}
            </div>
            {ProjetoMECIsOpen && (
              <div>
                {FotosProjetoMEC &&
                  FotosProjetoMEC.map((i) => (
                    <img src={i.url} alt={i.name} className={`FotosProjetos ${i.Name}`}></img>
                  ))}
              </div>
            )}
          </section>
        }

        {
          TypeContent.includes("RENDER") &&
          <section className='Drop'>
            <div className='HeaderDrop' onClick={HandleClickRender} style={{ marginBottom: !RenderIsOpen ? "10px" : "0" }}>
              {!RenderIsOpen ?
                <svg xmlns="http://www.w3.org/2000/svg" height="16" width="14" viewBox="0 0 448 512"><path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" /></svg>
                :
                <svg xmlns="http://www.w3.org/2000/svg" height="16" width="12" viewBox="0 0 384 512"><path d="M169.4 470.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 370.8 224 64c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 306.7L54.6 265.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" /></svg>
              }
              {"RENDERS"}
            </div>
            {RenderIsOpen && (
              <div>
                {FotosRender &&
                  FotosRender.map((i) => (
                    <img src={i.url} alt={i.name} className={`FotosProjetos ${i.Name}`}></img>
                  ))}
              </div>
            )}
          </section>
        }
      </main >
    </>
  )
}
export default ViwProjeto
