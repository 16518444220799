import NavBar from "../../../components/NAV-BAR"
import "./Home_Calculadore-Style.css"

function HomeCalculadora() {
    return (
        <>
            <head>
                <meta property="og:title" content="Portifolio || kawhe" />
                <meta property="og:url" content="https://kawhealvesdossantos.com.br/utilitarios" />
                <meta property="og:image" content="https://firebasestorage.googleapis.com/v0/b/kawhe-alves-dos-santos-db4be.appspot.com/o/1Prancheta%201.png?alt=media&token=54351338-cfe9-4ec2-95f0-79da42a59f1b" />
                <meta property="og:description" content="Pagina pricipal dos portifolios" />
                <meta name="description" content="Pagina pricipal dos portifolios" />
            </head>

            <title>KAWHE | UTILIRARIOS</title>
            <NavBar />
            <main>
                <div class="portifolio">
                    <div class="min-prj p1">
                        <a href={`/Utilitarios/calculadora`}>
                            <img className="ImageProjeto image-prj img-Calc" src={"https://firebasestorage.googleapis.com/v0/b/kawhe-alves-dos-santos-db4be.appspot.com/o/LOGO%20-%20SITE%20-%20CONVERÇÃO%20DE%20UNIDADES.png?alt=media&token=21102e7b-3a24-47d5-9861-db235b295763"} alt="PROJETO 01"></img>
                        </a>
                        <div class="text-s">
                            <p class="text text-calc">CAUCULADORA</p>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}
export default HomeCalculadora