import { useEffect, useState } from "react"
import NavBar from "../../components/NAV-BAR"
import "./Loja-Style.css"
import { collection, onSnapshot, query } from "firebase/firestore";
import { db } from "../../config/firebase";
import { toast } from "react-toastify";

function Loja() {

    const [Produtos, setProdutos] = useState([])

    useEffect(() => {
        const q = query(collection(db, 'Produtos'));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            let todosArr = [];
            querySnapshot.forEach((doc) => {
                todosArr.push({ ...doc.data() });
            });
            setProdutos(todosArr);
        });
        return () => unsubscribe();
    }, []);
    
    function AddToCart() {
        toast.error("Embreve")
    }

    return (
        <>

            <head>
                <meta property="og:title" content="KAWHE | Loja" />
                <meta property="og:url" content="https://kawhealvesdossantos.com.br/loja" />
                <meta property="og:image" content="https://firebasestorage.googleapis.com/v0/b/kawhe-alves-dos-santos-db4be.appspot.com/o/1Prancheta%201.png?alt=media&token=54351338-cfe9-4ec2-95f0-79da42a59f1b" />
                <meta property="og:description" content="Pagina inicial da Loja" />
                <meta name="description" content="Pagina inicial da Loja" />
            </head>

            <title>KAWHE | LOJA</title>

            <NavBar />
            <main>
                <div class="produtos">
                    {
                        Produtos && Produtos.map((i, index) => (
                            <div class="min-Prod p1" id={i.Id}>
                                <a href={`/loja/${i.Name}`}>
                                    <img className="ImageProduto image-prod" src={i.Url_Capa} alt={`PRODUTO ${index + 1}`}></img>
                                </a>
                                <div class="text-s">
                                    <p class="text">{i.Name}</p>
                                </div>
                                <div class="text-s">
                                    <p class="text-P">Preço: {i.Price}</p>
                                </div>
                                <div class="text-s">
                                    <button onClick={()=>AddToCart()}>Adicionar no carrinho</button>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </main>
        </>
    )
}
export default Loja