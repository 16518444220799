import { useEffect, useState } from "react";
import "./Redirects-Style.css"
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { db } from "../../config/firebase";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import NavBar from "../../components/NAV-BAR";

function Redirects() {

    const P = useParams();
    const [Links, setLinks] = useState(null);
    const [Texto, setTexto] = useState("REDIRECIONANDO EM");
    let time = P.time || 5
    const [countdown, setCountdown] = useState(time);

    useEffect(() => {
        const q = query(
            collection(db, 'Links'),
            where('LinkNV', '==', P.Nome + "/" + P.Ano + "/" + P.Pasta)
        );
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            if (querySnapshot.empty) {
                toast.error("Nenhum link cadastrado")
                setTexto("Nenhum link cadastrado, indo para home em")
                setTimeout(() => {
                    window.location.href = "/"
                }, 5000)
            } else {
                querySnapshot.forEach((doc) => {
                    setLinks({ ...doc.data() });
                });
            }
        });
        return () => unsubscribe();
    }, [P.Nome, P.Ano, P.Pasta]);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCountdown((prevCountdown) => prevCountdown - 1);
        }, 1000);
        return () => clearInterval(intervalId);
    }, []);

    const [abc, setabc] = useState("");
    useEffect(() => {
        if (countdown <= 0 && Links) {
            window.location.href = Links.LinkOG;
            setabc(Links.LinkOG)
        }
    }, [countdown, Links]);

    const [valor, setValor] = useState(472);
    const alvo = 0;
    const duracao = 5000; // 5 segundos
    const etapas = 60;

    useEffect(() => {
        const diferencaPorEtapa = (valor - alvo) / etapas;
        const reduzirGradualmente = (etapaAtual, tempoPassado) => {
            const novoValor = Math.max(valor - diferencaPorEtapa * etapaAtual, alvo);
            setValor(novoValor);
            if (tempoPassado < duracao) {
                setTimeout(() => reduzirGradualmente(etapaAtual + 1, tempoPassado + duracao / etapas), duracao / etapas);
            }
        };
        reduzirGradualmente(1, duracao / etapas);
    }, []); // O array vazio garante que o useEffect seja executado apenas uma vez

    useEffect(() => {
        try {
            (window.adsbygoogle = window.adsbygoogle || []).push({});
        } catch (e) {
            console.log("AdSense error: ", e);
        }
    }, []);

    return (
        <>

            <head>
                <meta property="og:title" content="KAWHE | REDIRECIONAMENTOS" />
                <meta property="og:url" content={`https://kawhealvesdossantos.com.br/encurtador/${Links && Links.LinkNV}`} />
                <meta property="og:image" content="https://firebasestorage.googleapis.com/v0/b/kawhe-alves-dos-santos-db4be.appspot.com/o/1Prancheta%201.png?alt=media&token=54351338-cfe9-4ec2-95f0-79da42a59f1b" />
                <meta property="og:description" content="Pagina de redirecionamento interno kawhe" />
                <meta name="description" content="Pagina de redirecionamento interno kawhe" />
            </head>

            <title>KAWHE | REDIRECIONANDO | {Links && Links.LinkNV}</title>


            <NavBar />

            <div className="Centred">
                <div className="anun">
                    <ins
                        class="adsbygoogle"
                        style={{ display: 'block' }}
                        data-ad-client="ca-pub-4495099356996404"
                        data-ad-slot="5891067729"
                        data-ad-format="auto"
                        data-full-width-responsive="true"
                    >
                    </ins>
                    <script>
                        (adsbygoogle = window.adsbygoogle || []).push({ });
                    </script>
                </div>
                <div className="content">
                    <p>{Texto}</p>
                    <div>
                        <svg viewBox="0 0 300 300" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="150" cy="150" fill="none"
                                r="75" stroke-width="15" stroke="#1e416e"
                                stroke-dasharray={`${valor} 1400`} />
                        </svg>
                        <p className="Time">
                            {countdown}
                        </p>
                    </div>
                    <p>SEGUNDOS ...</p>
                    <p>{Links && Links.LinkOG}</p>
                </div>
                <div className="anun">
                <ins
                        class="adsbygoogle"
                        style={{ display: 'block' }}
                        data-ad-client="ca-pub-4495099356996404"
                        data-ad-slot="5891067729"
                        data-ad-format="auto"
                        data-full-width-responsive="true"
                    >
                    </ins>
                    <script>
                        (adsbygoogle = window.adsbygoogle || []).push({ });
                    </script>
                </div>
            </div>
        </>
    )
}
export default Redirects;