import NavBar from '../../components/NAV-BAR';
import './index-home-style.css'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useEffect, useState } from 'react';
import { collection, onSnapshot, query } from 'firebase/firestore';
import { db } from '../../config/firebase';

function Home() {

  const [Projetos, setProjetos] = useState([])

  useEffect(() => {
    const q = query(collection(db, 'Projetos'));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      let todosArr = [];
      querySnapshot.forEach((doc) => {
        todosArr.push({ ...doc.data() });
      });
      setProjetos(todosArr);
    });
    return () => unsubscribe();
  }, []);

  const ConfigSlider1 = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 10000,
  };
  return (
    <>
      <head>
        <meta property="og:title" content="Portifolio || kawhe" />
        <meta property="og:url" content="https://kawhealvesdossantos.com.br" />
        <meta property="og:image" content="https://firebasestorage.googleapis.com/v0/b/kawhe-alves-dos-santos-db4be.appspot.com/o/1Prancheta%201.png?alt=media&token=54351338-cfe9-4ec2-95f0-79da42a59f1b" />
        <meta property="og:description" content="Aqui voçe pode ver alguns de meus projetos, e se ja meu cliente, pode acompanhar como esta o seu projeto" />
        <meta name="description" content="portifolio do kawhe alves dos santos." />
      </head>

      <title>KAWHE | HOME</title>
      <NavBar />
      <main className='MainHome'>
        <Slider {...ConfigSlider1} className='SliderHome'>
          {
            Projetos ? Projetos.map((i) => (
              <div key={i.Id}>
                <div className='itenscarrosel'>
                  <div className='STextosHome'>
                    <div className='LineHomeTextos'></div>
                    <h1>{i.Name}</h1>
                    <div className='LineHomeTextos'></div>
                    <h4>INFORMAÇÃOES DO PROJETO</h4>
                    <div className='LineHomeTextos'></div>
                    <div className='ItensTextosHome'>
                      <h6>PROJETO: {i.Type}</h6>
                      <h6>ESTRUTURA: {i.Estructure}</h6>
                      <h6>PARA: {i.From}</h6>
                    </div>
                    <div className='LineHomeTextos'></div>
                    <h4>INFORMAÇÃOES DE SOFTWARE</h4>
                    <div className='LineHomeTextos'></div>
                    <div className='ItensTextosHome'>
                      <h6>PROJETO FINAL: {i.FinalProject}</h6>
                      <h6>PRÉ PROJETO: {i.PreProject}</h6>
                      <h6>RENDER: {i.Render}</h6>
                      <h6>PDF: {i.Pdf}</h6>
                    </div>
                    <div className='LineHomeTextos'></div>
                    <h4>{i.Date}</h4>
                    <div className='LineHomeTextos'></div>
                  </div>

                  <div className='SImageHome'>
                    <a href={`/projetos/viw/${i.Name}`}>
                      <img src={i.Url_Capa} alt="" />
                    </a>
                  </div>
                </div>
              </div>
            )) : ""
          }
        </Slider>
      </main>
    </>
  );
}
export default Home;
